import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {Grid, Typography, Tooltip, IconButton} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { DeleteRowModal } from "../../shared/helper/tableHelper";
import ApiRoutes from "./ApiRoutes/ApiRoutes";
import ApiAuth from "./ApiAuth/ApiAuth";


const ApiComponent = ({endpoint, updateEndpoint, userPermissions}) => {
  const { t } = useTranslation("endpoints");
  const [routes, setRoutes] = useState([]); 
  const [auths, setAuths] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [routeEdit, setRouteEdit] = useState({});
  const [authEdit, setAuthEdit] = useState({});

  useEffect(() => {
    if (endpoint?.routes) {
      let tempRoutes = [...endpoint.routes];
      if (routes?.length>0) {
        for (let route of routes) {
          if (route.new && route.editing) {
            delete route.add;
            tempRoutes = [route, ...tempRoutes];
          }
        }
      }
      setRoutes(tempRoutes);
    }
    if (endpoint?.auth) {
      let tempAuths = [...endpoint.auth];
      if (auths?.length>0) {
        for (let auth of auths) {
          if (auth.new && auth.editing) {
            delete auth.add;
            tempAuths = [auth, ...tempAuths];
          }
        }
      }
      setAuths(tempAuths);
    }
  }, [endpoint.routes, endpoint.auth]);

  const handdleAddRoute = async () => {
    const newRoute = {
      name: "",
      type: "GET",
      route: "",
      params: [],
      options: {
        file: false,
        filePosition: false,
      },
      editing: true,
      new: true,
      add: true,
    };
    if (!routes.some((e)=> e.name === "")){
      const newArrayRoutes = [newRoute, ...routes];
      setRoutes(newArrayRoutes);
    }
  };

  const handdleDeleteRoute = (route) => {
    setOpenModal(true);
    setRouteEdit(route);
  };

  const deleteRoute = () => {
    const route = routeEdit;
    let updatedRoutes = [];
    if (routeEdit?.new) {
      updatedRoutes = routes.filter(e => e.name !== route.name);
      setRoutes(updatedRoutes);
    } else {
      updatedRoutes = routes.filter(e => e._id !== route._id);
      const body = {
        name: endpoint.name,
        host: endpoint.host,
        routes: updatedRoutes,
        auths: endpoint.auth,
      };
      updateEndpoint(endpoint, body);
    }
    setRouteEdit({});
  };

  const handdleAddAuth = async () => {
    const newAuth = {
      type: "http",
      payload: {
        username:{
          key: "username",
          value: "",
        },
        password: {
          key: "password",
          value: "",
        },
        loginRoute: "",
      },
      editing: true,
      new: true,
      add: true,
    };
    if (!auths.some((e)=> e.type === "")){
      const newArrayAuth = [newAuth, ...auths];
      setAuths(newArrayAuth);
    }
  };

  const handdleDeleteAuth = (auth) => {
    setOpenModal(true);
    setAuthEdit(auth);
  };

  const deleteAuth = () => {
    const auth = authEdit;
    let updateAuths = [];
    if (authEdit?.new) {
      updateAuths = auths.filter(e => (e.new !== true && e.name === auth.name));
      setAuths(updateAuths);
    } else {
      updateAuths = auths.filter(e => e._id !== auth._id);
      const body = {
        _id: endpoint._id,
        name: endpoint.name,
        host: endpoint.host,
        routes: endpoint.routes,
        auths: updateAuths,
      };
      setAuths(updateAuths);
      updateEndpoint(endpoint, body);
    }
    setAuthEdit({});
  };

  const saveRoute = (route) => {
    let updateRoutes = [...endpoint.routes];
    if (route.new) {
      const newRoute = {
        name: route.name,
        type: route.type,
        route: route.route,
        params: route.params,
        options: route.options,
      };
      updateRoutes = [newRoute, ...updateRoutes];
    } else {
      for (const editRoute of updateRoutes) {
        if (editRoute._id === route._id) {
          editRoute.name = route.name;
          editRoute.type = route.type;
          editRoute.route = route.route;
          editRoute.params = route.params;
          editRoute.options = route.options;
        }
      }
    }
    const body = {
      _id: endpoint._id,
      name: endpoint.name,
      host: endpoint.host,
      routes: updateRoutes,
      auths: endpoint.auth,
    };
    updateEndpoint(endpoint, body);
    setRoutes(updateRoutes);
  };

  const saveAuth = (auth) => {
    let updateAuths = [...endpoint.auth];
    if (auth.new) {
      const newAuth = {
        type: auth.type,
        payload: auth.payload,
        tokenName: auth.tokenName,
      };
      updateAuths = [newAuth, ...updateAuths];
    } else {
      for (const editAuth of updateAuths) {
        if (editAuth._id === auth._id) {
          editAuth.type = auth.type;
          editAuth.payload = auth.payload;
          editAuth.tokenName = auth.tokenName;
        }
      }
    }
    const body = {
      name: endpoint.name,
      host: endpoint.host,
      routes: endpoint.routes,
      auths: updateAuths,
    };
    updateEndpoint(endpoint, body);
    setAuths(updateAuths);
  };

  return (
    <Fragment>
      <Grid container columns={2} sx={{display:"flex", flexDirection:"row", justifyContent:"center"}}>
        <Grid item xs={1} columns={6}             
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems:"center",
            height:"auto",
          }}>
          <Grid item xs={6} columns={6} sx={{display: "flex", flexDirection: "row", alignItems:"center"}}>
            <Grid item xs={2} >
              <Typography variant="h6">
                {t("Rutas")}
              </Typography>
            </Grid>
            <Grid item xs={1} >
              <Tooltip
                title={userPermissions ? t("apiRoutes:tooltipCondition") : t("noPermissionMessage")}
                sx={{
                  display: "flex",
                  marginLeft: "auto",
                  "&:hover": { color: "#4e8cff" },
                }}>
                <span>
                  <IconButton
                    disabled={!userPermissions}
                    onClick={handdleAddRoute}>
                    <AddIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </Grid>
          </Grid>
          <Grid item xs={6} columns={6} sx={{display: "flex", flexDirection: "row", alignItems:"flex-start"}}>
            <ApiRoutes routes={routes} host={endpoint.host} modalDeleteRoute={handdleDeleteRoute} saveRoute={saveRoute} userPermissions={userPermissions}>
            </ApiRoutes>
          </Grid>
        </Grid>
        <Grid item xs={1} rows={10}             
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems:"center",
            marginLeft:"-2%",
          }}>
          <Grid item xs={1} rows={2} sx={{display: "flex", flexDirection: "row", alignItems:"flex-start"}}>
            <Grid item xs={1}>
              <Typography variant="h6">
                {t("Auth")}
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <Tooltip
                title={userPermissions ? t("apiAuths:tooltipCondition") : t("noPermissionMessage")}
                sx={{
                  display: "flex",
                  marginLeft: "auto",
                  "&:hover": { color: "#4e8cff" },
                }}>
                <span>
                  <IconButton
                    disabled={!userPermissions}
                    onClick={handdleAddAuth}>
                    <AddIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </Grid>
          </Grid>
          <Grid item xs={9} sx={{display: "flex", flexDirection: "column", alignItems:"flex-start", justifyContent:"flex-start"}}>
            <ApiAuth auths={auths} host={endpoint.host} modalDeleteAuth={handdleDeleteAuth} saveAuth={saveAuth} userPermissions={userPermissions}>
            </ApiAuth>
          </Grid>
        </Grid>
      </Grid>
      <DeleteRowModal
        open={openModal}
        onClose={() => 
          setOpenModal(false)}
        onDelete={() => {
          setOpenModal(false);
          if (Object.keys(routeEdit).length > 0) {
            deleteRoute();
          } else if (Object.keys(authEdit).length > 0) {
            deleteAuth();
          }

        }}
      />
    </Fragment>
  );
};

export default ApiComponent;